import type { AppUrl } from "@tamarack/shared/AppUrl";
import Link from "@tamarack/shared/Link";
import { twMerge } from "tailwind-merge";
import { generateDisabledProps } from "./Button";
import type { IconButtonProps } from "./IconButton";
import IconButton from "./IconButton";
import CloseIcon from "./icons/CloseIcon";
import MinusIcon from "./icons/MinusIcon";

export type CloseButtonProps = Omit<IconButtonProps, "size" | "variant"> & {
  size?: number;
  iconClassName?: string;
  href?: string | AppUrl;
  onClose?: () => void;
  variant?: "close" | "minimize";
};

export default function CloseButton({
  size = 20,
  iconClassName,
  href,
  variant = "close",
  disabled,
  disabledDescription,
  ...buttonProps
}: CloseButtonProps) {
  const closeIcon =
    variant === "close" ? (
      <CloseIcon
        size={size}
        className={twMerge(
          "opacity-60 group-hover:opacity-90 group-active:opacity-100",
          iconClassName
        )}
      />
    ) : (
      <MinusIcon
        size={size}
        className={twMerge(
          "opacity-60 group-hover:opacity-90 group-active:opacity-100",
          iconClassName
        )}
      />
    );

  return (
    <IconButton
      {...generateDisabledProps({ disabled, disabledDescription })}
      {...buttonProps}
      aria-label="Close"
      asChild
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();

          buttonProps.onClose?.();
        }
      }}
      onMouseDown={
        href
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();

              buttonProps.onMouseDown?.(e);
              buttonProps.onClose?.();
            }
      }
      onClick={
        href
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();

              buttonProps.onClick?.(e);
            }
      }
    >
      {href ? (
        <Link to={href} prefetch="intent">
          {closeIcon}
        </Link>
      ) : (
        <span role="button">{closeIcon}</span>
      )}
    </IconButton>
  );
}
