import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function MinusIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <>
          <path d="M0 0h24v24H0z" fill="none" />
          <path fill="currentColor" d="M19 13H5v-2h14v2z" />
        </>
      }
    />
  );
}
